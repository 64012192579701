<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Apply Employee Leaves</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name:'leave-approvals'}">Leaves Approval</router-link>
                </li>
                <li class="breadcrumb-item active">Apply</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form>
                    <div class="form-group row">
                        <label class="col-md-3">
                            Employee
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <EmployeeList :reporting_to="employeeReportingto" @emp_id="getEmpId"></EmployeeList>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label class="col-md-3">
                            From Date
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-input-group size="md" class="mb-1em">
                                <date-pick
                                    v-model="apply_leave.from_date"
                                    :inputAttributes="{readonly: true}"
                                ></date-pick>
                            </b-input-group>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            To Date
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-input-group size="md" class="mb-1em">
                                <date-pick
                                    v-model="apply_leave.to_date"
                                    :inputAttributes="{readonly: true}"
                                ></date-pick>
                            </b-input-group>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Leave Type:
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-select
                                v-model="apply_leave.type"
                                :options="lop_options"
                                @change="leaveChange()"
                            ></b-form-select>
                            <div class="row p-2" v-if="apply_leave.type != 'LOP'">
                                <div class="col-6">
                                    <span class="">Remaining: {{ remaining_leaves.leaveBalance }}</span> 
                                </div>
                                <div class="col-6">
                                    <span class="">Applied: {{ remaining_leaves.applied_leaves }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Reason
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-textarea
                                id="textarea"
                                v-model="apply_leave.reason"
                                placeholder="Please enter reason"
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>
                        </div>
                    </div>

                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                @click="showModal('applyEmployeeLeaveModal')"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                @click="$router.go(-1)"
                                class="btn btn-secondary ma-10px"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <b-modal
            id="applyEmployeeLeaveModal"
            no-close-on-backdrop
            no-close-on-esc
            title="Submit Confirmation"
            centered
        >
            <div class="d-block">
                <p>Are you sure you want Apply Employee Leave?</p>
            </div>
            <div slot="modal-footer" class="w-100" >
                <b-button class="mt-3 float-right" @click="hideModal('applyEmployeeLeaveModal')">No</b-button>
                <b-button
                    class="mt-3 float-right"
                    variant="danger"
                    style="margin-right:10px;"
                    @click="applyLeave()"
                >Yes</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
import EmployeeList from "@/components/EmployeeList.vue"
export default {
    components: {
        NotFound,
        DatePick,
        EmployeeList
    },
    data() {
        return {
            apply_leave: {
                reason: "",
                from_date: new Date().toISOString().slice(0, 10),
                to_date: new Date().toISOString().slice(0, 10),
                emp_id: "",
                type: 'LOP'
            },
            employeeReportingto:1,
            lop_options: ['GENERAL','LOP'],
            api_error: "",
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            remaining_leaves: '',
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_APPLY_TEAM_LEAVES");
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getEmpId(id) {
            this.apply_leave.emp_id = id;
        },
        
        applyLeave: function() {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {};
            this.hideModal('applyEmployeeLeaveModal');
            this.axios.post(this.$api.employee_leave_apply, this.apply_leave, query).then(() => {
                this.updateProgressBar(true);
                this.$router.push({name: 'leave-approvals'});
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        leaveChange() {
            if(this.apply_leave.type != 'GENERAL'){
                return false;
            }
            if(!this.apply_leave.emp_id) {
                this.api_error = "Please select Employee";
                this.apply_leave.type = 'LOP'
                this.showAlert();
                return false;
            }
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {
                emp_id: this.apply_leave.emp_id,
            };
            this.axios.get(this.$api.get_remaining_leaves, query).then(response => {
                this.remaining_leaves = response.data.data;
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}
</style>